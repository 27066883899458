<template>
  <div>
    <b-row class="content-header">
  <b-col class="content-header-left mb-3" cols="12">
    <b-row class="breadcrumbs-top">
      <b-col cols="12" md="8">
        <h2 class="content-header-title text-center text-md-left pr-1 mb-0 text-white">
          Access Control
        </h2>
      </b-col>
      <b-col cols="12" md="4">
        <div class="d-flex justify-content-center justify-content-md-end align-items-center">
          <b-button
            @click="openInviteControl()"
            variant="primary"
            class="mr-2 mb-2 mb-md-0"
            style="min-width: 150px;"
          >
            <span style="font-size:12px;font-weight:bold">
              <feather-icon
                style="margin-right: 2px;"
                icon="UserPlusIcon"
              ></feather-icon>
              Invite User
            </span>
          </b-button>
          <b-button
            v-if="adminRole == 1"
            @click="editConfiguration()"
            variant="primary"
            class="mr-2 mb-2 mb-md-0"
            style="min-width: 200px;"
          >
            <span style="font-size:12px;font-weight:bold">
              <feather-icon
                style="margin-right: 2px;"
                icon="ToolIcon"
              ></feather-icon>
              Edit Configuration
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-col>
</b-row>


    <b-card no-body class="py-0 transparent-card">
      <b-card-body class="py-0">
        <b-row class="table-title ">
          <b-col cols="3" class="text-left"
            ><span class="text-white">Client</span></b-col
          >
          <b-col cols="3" class="text-center"
            ><span class="text-white">People with Access</span></b-col
          >
          <b-col cols="3" class="text-center"
            ><span class="text-white">Role</span></b-col
          >
          <b-col cols="3" class="text-center"
            ><span class="text-white">Options</span></b-col
          >
        </b-row>
      </b-card-body>
    </b-card>
    <div
      style="align-items: center; justify-content: center; text-align: center; padding: 30px;"
      v-if="isAccessProfileLoad"
    >
      <Spinner />
    </div>
    <b-card
      :class="index === 0 ? 'mb-1' : 'my-1'"
      v-for="(client, index) in displayedAccessControlItems"
      :key="client._id"
    >
      <b-row>
        <b-col cols="3">
          <div class="d-flex align-items-center">
            <b-avatar
              size="40px"
              :variant="getRandomVariant(true)"
              :text="avatarText(client.name)"
              class="mx-1"
              id="avatar"
            />
            <div class="access-control-client-header">
              <span>
                <h3
                  v-b-tooltip.hover
                  class="app-title sm mb-0 text-overflow-handler"
                  :title="client.name"
                >
                  {{ client.name }}
                </h3></span
              >
              <span class="d-block sub-text">@{{ client.slug }}</span>
            </div>
            <!-- <div class="access-control-client-header">
              <span class="text-nowrap"
                ><h3 class="app-title sm mb-0">{{ client.name }}</h3></span
              >
              <span class="d-block sub-text">@{{ client.slug }}</span>
            </div> -->
          </div>
        </b-col>
        <b-col
          cols="3"
          class="text-center d-flex align-items-center justify-content-around"
        >
          <div>
            <b-avatar-group>
              <b-avatar
                v-for="(access, index) in client.access"
                :key="index"
                :variant="getRandomVariant()"
                v-b-tooltip.hover
                class="pull-up"
                :title="
                  `${
                    access.adminID.firstname ? access.adminID.firstname : ''
                  } ${access.adminID.lastname ? access.adminID.lastname : ''}`
                "
                :text="avatarText(access.adminID.firstname)"
              />
            </b-avatar-group>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="text-center d-flex align-items-center justify-content-around"
        >
          {{ client.role }}
        </b-col>
        <b-col
          cols="3"
          class="text-center d-flex align-items-center justify-content-around"
        >
          <div class="text-center" v-if="client.role === 'ADMIN'">
            <b-button
              @click="
                initAccessControl({ clientID: client._id, name: client.name })
              "
              size="sm"
              variant="flat-primary"
              ><feather-icon icon="UserPlusIcon"></feather-icon
            ></b-button>
            <b-button
              @click="
                deleteClientInit({ clientID: client._id, name: client.name })
              "
              size="sm"
              class=""
              variant="flat-primary"
              ><feather-icon icon="Trash2Icon"></feather-icon
            ></b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <AccessControlPopUp
      @closePopUp="closeAccessControlPopUp"
      :client="shareAccessClientDetails"
      :isProcessingAccess="isProcessingAccess"
      :isProcessingRevokeAccess="isProcessingRevokeAccess"
      :show="shareAccessPopUp"
      @send-invite="sendInvite"
      @remove-access="removeAccess"
    />

    <UserInvitePopUp
      @closePopUp="closeUserInvitePopUp"
      :client="shareAccessClientDetails"
      :isProcessingAccess="isProcessingAccess"
      :isProcessingRevokeAccess="isProcessingRevokeAccess"
      :show="userInvitePopUp"
      @send-invite="sendInvite"
      @remove-access="removeAccess"
    />

    <DeleteConfirmation
      @closePopUp="closeDeleteConfirmationPopUp"
      :isBusy="isBusy"
      :show="deleteConfirmationPopUp"
      :deleteClientDetails="deleteClientDetails"
      @confirmDelete="triggerDeleteClient"
    />
    <b-pagination
      v-if="this.clients.length > 0"
      v-model="currentPage"
      :total-rows="totalAccessControlClient"
      :per-page="perPage"
      first-number
      last-number
      align="right"
      prev-class="prev-item"
      next-class="next-item"
    >
    </b-pagination>
    <b-modal
      no-close-on-backdrop
      v-model="showEditConfiguration"
      id="modal-scrollable"
      hide-footer
      hide-header
      centered
    >
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closeEditConfigurationPopUp" variant="flat-primary"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <div class="edit-form">
          <h3 class="app-title md">Update Configuration</h3>
        </div>
      </b-card-text>
      <b-form-group
        label="Edit Dashboard Configuration For"
        label-for="invite-people"
        class="mt-2"
      >
        <div class="form-container">
          <v-select
            placeholder="Search Email ID"
            @open="fetchUsers"
            v-model="newAccess"
            label="email"
            :options="users"
            :loading="fetchingUsers"
            @search="checkEmail"
            class="full-width"
            :clearable="!singleSelection"
            no-data-text=""
            :deselectFromDropdown="true"
          />
        </div>
      </b-form-group>
      <b-form-group
        v-if="Object.keys(newAccess).length > 0"
        label="Enable Configuration"
        label-for="invite-people"
        class="mt-2"
      >
        <div class="form-container">
          <template>
            <div class="container-config">
              <div v-for="(value, key) in options" :key="key" class="option"  v-if="key !== 'clients'">
                <div class="card-config animate"  >
                  <h3
                    style="display: flex; justify-content: space-between; align-items: center; text-transform: capitalize; color: #200E6B; font-weight: bold; font-size: 14px"
                  >
                    {{ convertedString(key) }} 
                    <input
                      v-if="!isObject(value)"
                      type="checkbox"
                      v-model="options[key]"
                      style="display: inline-block; vertical-align: middle; width:15px !important; height:15px !important"
                    />
                  </h3>
                  <template v-if="isObject(value)">
                    <template v-for="(subValue, subKey) in value">
                      <div
                        :key="subKey"
                        v-if="isObject(subValue)"
                        class="nested" style="margin-top: 5px;" 
                      > 
                        <span v-if="isCollapsed" style="cursor: pointer;">
                          <feather-icon
                            v-if="value.enabled == true"
                            @click="toggleCollapse"
                            icon="MinusCircleIcon"
                            color="blue"
                          /> </span
                        ><span
                          style="cursor: pointer;"
                          @click="toggleCollapse"
                          v-else
                        >
                          <feather-icon
                            v-if="value.enabled == true"
                            icon="PlusCircleIcon"
                            color="blue"
                            v-b-tooltip.hover.top="'View More'"
                          />
                        </span>
                        <ul v-if="isCollapsed" class="animate">
                          <li
                            v-for="(nestedValue, nestedKey) in subValue"
                            :key="nestedKey"
                            class="nested-item"
                          >
                            <span
                              style="display: inline-block; vertical-align: middle; text-transform: capitalize;"
                              >{{ convertedString(nestedKey) }}</span
                            >
                            <input
                              style="display: inline-block; vertical-align: middle; text-transform: capitalize;width:15px !important; height:15px !important"
                              type="checkbox"
                              v-model="subValue[nestedKey]"
                            />
                          </li>
                        </ul>
                      </div>
                      <div v-else :key="subKey" class="nested-item" style="margin-top: -20px;">
                        <span></span>
                        <input
                          style="display: inline-block; vertical-align: middle; text-transform: capitalize;width:15px !important; height:15px !important"
                          type="checkbox"
                          v-model="value[subKey]"
                        />
                      </div>
                    </template>
                  </template>
                  <!-- <div v-else :key="key" class="nested-item">
                    <span
                      style="display: inline-block; vertical-align: middle; justify-content: space-between; text-transform: capitalize;"
                      >{{ convertedString(key) }}</span
                    >
                    <input
                      type="checkbox"
                      v-model="options[key]"
                      style="display: inline-block; vertical-align: middle;"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </template>
        </div>
        <div style="float: right; margin-top: 20px;">
          <b-button
            variant="primary"
            @click="updateConfiguration"
            :disabled="isValidInput"
            >Update Configuration <b-spinner v-if="isUpdateSuccessFully" small
          /></b-button>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BAvatarGroup,
  VBTooltip,
  BButton,
  BCardBody,
  BOverlay,
  BSpinner,
  BModal,
  BCardText
} from "bootstrap-vue";
import Spinner from "@/components/client/Spinner.vue";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import AccessControlPopUp from "@/components/client/AccessControlPopUp.vue";
import UserInvitePopUp from "@/components/client/UserInvitePopUp.vue";
import DeleteConfirmation from "@/components/client/DeleteConfirmation.vue";
import Cookies from "js-cookie";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BCard,
    BAvatarGroup,
    BButton,
    BCardBody,
    AccessControlPopUp,
    UserInvitePopUp,
    DeleteConfirmation,
    ToastificationContent,
    BOverlay,
    BSpinner,
    Spinner,
    BModal
  },
  data() {
    return {
      todayDateCompare: new Date().toISOString().split('T')[0],
      singleSelection: true,
      value: null,
      isCollapsed: false,
      options: [],
      adminRole: null,
      isUpdateSuccessFully: false,
      showEditConfiguration: false,
      isAccessProfileLoad: false,
      perPage: 8,
      currentPage: 1,
      clients: [],
      isBusy: true,
      variants: {
        0: "danger",
        1: "warning",
        2: "pink",
        3: "purple"
      },
      shareAccessPopUp: false,
      userInvitePopUp: false,
      shareAccessClientDetails: {
        name: "",
        clientID: ""
      },
      isProcessingAccess: false,
      isProcessingRevokeAccess: false,
      deleteConfirmationPopUp: false,
      deleteClientDetails: {
        name: "",
        clientID: ""
      },
      users: [],
      fetchingUsers: false,
      newAccess: [],
      configurationEmail: "",
      updatedOneArray: {},
      updatingFor: ""
    };
  },
  created() {
    this.adminRole = Cookies.get(useJwt.jwtConfig.roleForConfiguration);
  },
  watch: {
    options: {
      deep: true,
      handler(newValue, oldValue) {
        this.updatedOneArray = {};
        this.updatedOneArray = newValue;
      }
    },
    newAccess(newVal) {
      this.options = [];
      this.configurationEmail = [];
      this.updatingFor = newVal.firstname + " " + newVal.lastname;
      this.configurationEmail = newVal.email;
      this.options = newVal.configuration;
    }
  },
  computed: {
    isValidInput() {
      return this.newAccess.length == 0;
    },
    totalAccessControlClient() {
      return this.clients.length;
    },
    displayedAccessControlItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.clients.slice(start, end);
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  methods: {
    addActivityLog() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/logs/`, {
              "description": "Access Control Opened",
              "action": "view"
          }, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
        })
        .catch(error => {
        });
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    isObject(value) {
      return typeof value === "object" && value !== null;
    },
    toggleOption(key) {
      if (this.options.hasOwnProperty(key)) {
        this.options[key] = !this.options[key];
      }
    },
    updateConfiguration() {
      this.isUpdateSuccessFully = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/updateConfiguration`,
          {
            email: this.configurationEmail,
            configuration: this.updatedOneArray
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.closeEditConfigurationPopUp();
          this.showToast(
            "Success!",
            "Updated Configuration Successfully",
            "success"
          );
          this.isUpdateSuccessFully = false;
        })
        .catch(error => {
          this.isUpdateSuccessFully = false;
          this.closeEditConfigurationPopUp();
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
        });
    },
    fetchUsers() {
      this.fetchingUsers = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/get-all`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.fetchingUsers = false;
          this.users = response.data.admins;
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleOptionCheck(newValue) {
      this.options.forEach(option => {
        option.flag = newValue.includes(option.id);

        if (option.children) {
          option.children.forEach(childOption => {
            childOption.flag = newValue.includes(childOption.id);
          });
        }
      });
    },
    checkEmail(searchValue) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; // Regex pattern for email validation
      this.isValidEmail = emailRegex.test(searchValue.toLowerCase());
      this.showAddButton = true;
      this.correctEmail = searchValue;
      for (const email of this.users) {
        if (email.email.toLowerCase().includes(searchValue.toLowerCase())) {
          this.showAddButton = false;
          break;
        }
      }
    },
    editConfiguration() {
      this.showEditConfiguration = true;
    },
    closeEditConfigurationPopUp() {
      this.newAccess = [];
      this.showEditConfiguration = false;
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant
        }
      });
    },
    fetchClients() {
      this.isBusy = true;
      this.isAccessProfileLoad = true;
      const storedDate = Cookies.get('lastVisitedAccessControlDate');
      if (storedDate === this.todayDateCompare) {
       console.log("");
      } else {
        this.addActivityLog();
        Cookies.set('lastVisitedAccessControlDate', this.todayDateCompare, { expires: 1 }); 
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/client/access-control`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.isAccessProfileLoad = false;
          this.clients = response.data.clients;
          this.isBusy = false;
        })
        .catch(error => {
          this.isAccessProfileLoad = false;
          console.log(error.response.data);
        });
    },
    getRandomVariant(light = false) {
      let randomSelect = Math.floor(Math.random() * 4);
      let variant = this.variants[randomSelect];
      if (light) {
        variant = "light-" + variant;
      }
      return variant;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map(word => word.charAt(0).toUpperCase()).join("");
    },
    closeAccessControlPopUp() {
      this.shareAccessPopUp = false;
      this.shareAccessClientDetails = {
        name: "",
        clientID: ""
      };
    },
    closeUserInvitePopUp() {
      this.userInvitePopUp = false;
      // this.shareAccessClientDetails = {
      //   name: "",
      //   clientID: "",
      // };
    },
    sendInvite(accessListData) {
      this.isProcessingAccess = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/add-access`,
          {
            accessList: accessListData.accessList,
            clientID: accessListData.clientID
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(async response => {
          let newAccessList = await this.getAccessList(accessListData.clientID);
          await this.fetchClients();
          this.shareAccessClientDetails.accessList = newAccessList;
          this.showToast(
            "Success!",
            "Invitation Sent Successfully",
            "success"
          );
          this.isProcessingAccess = false;
        })
        .catch(error => {
          this.showToast("Error", "Unknown error occured.", "danger");
          console.log(error);
        });
    },
    convertedString(name) {
      if (!name) return;
      // Split the string by underscores and join with spaces
      return name.split("_").join(" ");
    },
    removeAccess(data) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/remove-access`,
          data,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(async response => {
          this.isProcessingRevokeAccess = false;
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    },
    getAccessList(clientID) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/client/get-access-list`,
            { clientID },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`
              }
            }
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async initAccessControl(client) {
      try {
        this.shareAccessClientDetails = client;
        let accessList = await this.getAccessList(client.clientID);
        this.shareAccessClientDetails.accessList = accessList;
        this.shareAccessPopUp = true;
      } catch (error) {
        this.showToast("Error", "Unknown error occured!", "danger");
      }
    },
    async openInviteControl() {
      try {
        this.userInvitePopUp = true;
      } catch (error) {
        this.showToast("Error", "Unknown error occured!", "danger");
      }
    },
    isObject(val) {
      return val !== null && typeof val === "object";
    },
    closeDeleteConfirmationPopUp() {
      this.deleteConfirmationPopUp = false;
      this.deleteClientDetails = {
        name: "",
        clientID: ""
      };
    },
    deleteClientInit(client) {
      this.deleteConfirmationPopUp = true;
      this.deleteClientDetails = {
        clientID: client.clientID,
        name: client.name
      };
    },
    triggerDeleteClient(client) {
      this.isBusy = true;
      let clientIndex = this.clients.findIndex(clientObj => {
        return (clientObj._id = client.clientID);
      });
      if (clientIndex > -1) {
        axios
          .post(`${process.env.VUE_APP_SERVER_URL}/api/client/delete`, client, {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          })
          .then(response => {
            this.isBusy = false;
            this.deleteConfirmationPopUp = false;
            this.fetchClients();
          })
          .catch(error => {
            console.log(error);
            this.displayError(error.response.data);
            this.isBusy = false;
          });
      } else {
        alert("Invalid client selected!");
      }
    }
  },
  mounted() {
    this.fetchClients();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.animate {
  animation-duration: 1s; /* Increase the duration to 1s for a smoother effect */
  animation-name: animate-fade-move;
  animation-fill-mode: forwards; /* Change to 'forwards' to keep the final state */
}

@keyframes animate-fade-move {
  0% {
    opacity: 0;
    transform: translateX(50px); /* Adjust the value based on your needs */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.container-config {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 20px !important;
  justify-content: center !important;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.card-config {
  padding: 10px;
  border: none;
  margin-bottom: 0.5rem;
  width: 220px;
  border-radius: 5px;

  box-shadow: $box-shadow;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .nested-item {
    margin-left: 0px;
    vertical-align: middle;
    // margin-top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input[type="checkbox"] {
    margin-right: 6px;
  }
}

.form-container {
  display: flex;
  align-items: center;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
}
.table-title {
  span {
    font-size: 12px;
    font-weight: 500;
    color: #666;
  }
}

.card.transparent-card {
  box-shadow: none;
  background-color: transparent;
  margin-bottom: 10px;
}

.access-control-client-header {
  .sub-text {
    color: #6b6b6b;
    font-size: 12px;
    margin-top: 3px;
  }
  width: 250px;
}

.text-overflow-handler {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Ensure the ellipsis works correctly */
}

@media only screen and (min-width: 767px) and (max-width: 1281px) {
  .access-control-client-header {
    width: 100px; /* Set the desired width */
  }
}

@media only screen and (max-width: 767px) {
  #avatar {
    display: none;
  }
  .access-control-client-header {
    width: 70px; /* Set the desired width */
  }
}
</style>
